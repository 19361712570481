<template>
  <v-dialog
    v-model="dialog"
    max-width="450px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        color="light-green"
        title="Modificar alicuota"
        class="ml-2"
        small
      >
        fas fa-percentage
      </v-icon>
    </template>
    
    <v-card>
      <v-card-title>
        Modificar alicuota
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-6 pb-2">
        <v-row class="d-flex align-center justify-center">
          <v-col cols="12" sm="8" class="pt-0">
            {{ p_financiacion.descripcion  }}
          </v-col>
          <v-col cols="7" sm="4" class="pb-0">
            <v-form ref="form">
              <v-text-field
                v-model="alicuota"
                label="Nueva alicuota"
                type="number"
                suffix="%"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="6" class="py-1">
            <FechaPicker
              v-model="desde"
              label="Vig. desde"
            />
          </v-col>
          <v-col cols="6" class="py-1">
            <FechaPicker
              v-model="hasta"
              label="Vig. hasta"
              :key="keyHasta"
              :fecha_minima="desde ? moment(parseDate(desde)).format('DD/MM/YYYY') : null"
              :disabled="desde == null"
              :clearable="true"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="error"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar la alicuota de una financiacion
 */
import { parseDate } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import FechaPicker from '../util/FechaPicker'
import moment from 'moment'

export default {
  data () {
    return {
      moment: moment,
      parseDate: parseDate,
      keyHasta: 1,
      dialog: false,
      desde: moment(new Date()).format('DD/MM/YYYY'),
      hasta: null,
      alicuota: null,
      rules: {
        required: value => !!value || 'Campo requerido'
      }
    }
  },
  props: {
    p_financiacion: Object
  },
  computed: {
    financiacion: {
      get () {
        return this.p_financiacion
      },
      set (value) {
        this.$emit('update:p_financiacion', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    FechaPicker
  },
  watch: {
    dialog (val) {
      val || this.limpiar()
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        // valido que haya ingresado fecha desde
        if (!this.desde) {
          return this.$store.dispatch('show_snackbar', {
            text: 'Debe ingresar una vigencia desde',
            color: 'orange'
          })
        }

        // obtiene la alicuota
        let alicuota = JSON.parse(JSON.stringify(this.alicuota))
       
        this.dialog = false
        // si no modifico la alicuota no hace nada
        if (alicuota != this.get_alicuota()) {
          this.$store.state.loading = true
          await this.$store.dispatch('financiaciones/cambiar_alicuota', {
            id: this.p_financiacion.id,
            desde: this.desde,
            hasta: this.hasta,
            alicuota: alicuota
          })
            .then((res) => {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              this.financiacion.alicuota = alicuota
              this.financiacion.desde = JSON.parse(JSON.stringify(this.desde))
              this.financiacion.hasta = JSON.parse(JSON.stringify(this.hasta))
              this.financiacion.id = res.id
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
        } else {
          this.$store.dispatch('show_snackbar', {
            text: 'No se modifico la alicuota: el valor sigue siendo igual que el anterior',
            color: 'orange'
          })
        }
      }
    },
    get_alicuota () {
      let alicuota = JSON.parse(JSON.stringify(this.p_financiacion.alicuota))
      // formatea la alicuota
      if (alicuota % 1 == 0 || alicuota == 0) {
        return alicuota.toString()
      } else {
        return alicuota.toFixed(2)
      }
    },
    limpiar () {
      this.alicuota = null
      this.desde = moment(new Date()).format('DD/MM/YYYY')
      this.hasta = null
      this.keyHasta ++
    }
  }
}
</script>