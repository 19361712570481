<template>
  <v-dialog
    v-model="dialog"
    width="750"
  >
    <v-card>
      <v-card-title>
        Seleccionar tarjetas
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-3">
        <v-row class="my-4">
          <v-col cols="12" sm="9" class="py-0">
            <v-autocomplete
              v-model="tarjetas_sel"
              label="Tarjetas"
              item-text="nombre"
              :items="tarjetas.filter(tar => !tarjetas_masiva.find(tm => tm.id == tar.codigo) || tarjetas_masiva.length == 0)"
              deletable-chips
              return-object
              hide-details
              small-chips
              clearable
              multiple
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" class="py-0 d-flex justify-sm-start justify-end">
            <v-btn
              color="success"
              class="mt-3 mt-sm-0"
              small
              @click="agregar()"
            >
              <v-icon small left>fas fa-plus</v-icon>
              Agregar
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          class="elevation-2 cebra mt-4"
          :items="tarjetas_masiva"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:[`item.tarjeta`]="{ item }">
            {{ item.nombre }}
          </template>
          <template v-slot:[`item.cuotas`]="{ item }">
            <v-autocomplete
              v-model="item.cuotas"
              item-text="nombre"
              class="my-2"
              :items="cuotas.filter(c => c.tarjeta_codigo == item.id && c.empresa_codigo == p_empresa_id)"
              deletable-chips
              return-object
              hide-details
              small-chips
              clearable
              multiple
              outlined
              dense
              @blur="cargar_cuotas(item)"
            ></v-autocomplete>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="error"
              title="Quitar"
              small
              @click="quitar(item)"
            >
              fas fa-times-circle
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="secondary"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { order_list_by } from '../../util/utils'

export default {
  data () {
    return {
      cuotas: [],
      tarjetas_sel: [],
      headers: [
        { text: 'Tarjeta', value: 'tarjeta', sortable: false, filterable: false },
        { text: 'Cuotas', value: 'cuotas', sortable: false, filterable: false },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false },
      ]
    }
  },
  props: {
    value: Boolean,
    p_tarjetas: Array,
    p_cuotas: Array,
    p_empresa_id: Number
  },
  computed: {
    ...mapState('tarjetas', ['tarjetas']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    tarjetas_masiva: {
      get () {
        return this.p_tarjetas
      },
      set (value) {
        this.$emit('update:p_tarjetas', value)
      }
    },
    cuotas_masiva: {
      get () {
        return this.p_cuotas
      },
      set (value) {
        this.$emit('update:p_cuotas', value)
      }
    }
  },
  watch: {
    async dialog (val) {
      if (val) {
        await new Promise(resolve => setTimeout(resolve, 1))
        if (this.cuotas.length == 0) {
          this.$store.state.loading = true
          await this.$store.dispatch('cuotas/get_cuotas', { inhabilitada: 0 })
            .then((res) => {
              this.cuotas = res.data
              order_list_by(this.cuotas, 'nombre')
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
        }
      }
      else {
        this.tarjetas_sel = []
      }
    }
  },
  methods: {
    async cargar_cuotas (item) {
      await this.quitar_cuotas(item.id)
      if (item.cuotas) {
        for (const cuota of item.cuotas) {
          const existe = await this.cuotas_masiva.find(c => c.id == cuota.id)
          if (!existe) {
            await this.cuotas_masiva.push({
              id: cuota.codigo,
              nombre: item.nombre + ' - ' + cuota.nombre,
              tarjeta: cuota.tarjeta_codigo
            })
          }
        }
        order_list_by(this.cuotas_masiva, 'nombre')
      }
    },
    async agregar () {
      for (const tarjeta of this.tarjetas_sel) {
        const existe = await this.tarjetas_masiva.find(t => t.id == tarjeta.id)
        if (!existe) {
          await this.tarjetas_masiva.push({
            id: tarjeta.codigo,
            nombre: tarjeta.nombre
          })
        }
      }
      order_list_by(this.tarjetas_masiva, 'nombre')
      this.tarjetas_sel = []
    },
    async quitar (item) {
      await this.quitar_cuotas(item.id)
      const index = await this.tarjetas_masiva.indexOf(item)
      await this.tarjetas_masiva.splice(index, 1)
    },
    async quitar_cuotas (tarjeta_id) {
      for (const cuota of this.cuotas_masiva.filter(c => c.tarjeta == tarjeta_id)) {
        const item = await this.cuotas_masiva.find(c => c.id == cuota.id)
        const index = await this.cuotas_masiva.indexOf(item)
        if (index >= 0) {
          await this.cuotas_masiva.splice(index, 1)
        }
      }
    }
  }
}
</script>