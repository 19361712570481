<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_window(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="6" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    autofocus
                    outlined
                    dense
                    @change="get_cuotas()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal_codigo"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    outlined
                    dense
                    @change="get_cuotas()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Tarjetas
                  <v-autocomplete
                    v-model="filtro.tarjetas"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="tarjetas"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                    @blur="get_cuotas()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" class="py-1">
                  Cuotas
                  <v-autocomplete
                    v-model="filtro.cuotas"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="cuotas"
                    :readonly="filtro.tarjetas.length > 1"
                    :filled="filtro.tarjetas.length > 1"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" class="py-1">
                  Formas de pago
                  <v-autocomplete
                    v-model="filtro.formas_pago"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="formas_pago"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" class="py-1">
                  Formas de cobro
                  <v-autocomplete
                    v-model="filtro.formas_cobro"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="formas_cobro"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Descripción
                  <v-text-field
                    v-model.trim="filtro.descripcion"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Canales
                  <v-autocomplete
                    v-model="filtro.canal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="canales"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="filtro.inhabilitada"
                    label="Inhabilitada"
                    class="mb-2"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-data-table
        v-model="selected"
        item-key="id"
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="financiaciones"
        :loading="load"
        :search="search"
        :sort-by="['descripcion']"
        show-select
        multi-sort
        dense
        @toggle-select-all="selectAllToggle"
        @contextmenu:row="clickDerecho"
        :item-class="setStyle"
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.alicuota`]="{ item }">
          {{ item.alicuota % 1 == 0 ? item.alicuota : parseFloat(item.alicuota).toFixed(2) }}%
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_window(item.id)"
          >
            fas fa-eye
          </v-icon>
          <BtnConfirmar
            :icono="item.inhabilitada ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.inhabilitada ? 'success' : 'error'"
            :title="item.inhabilitada ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.inhabilitada
              ? `¿Desea habilitar la financiación <strong>${item.descripcion}</strong>?`
              : `¿Desea inhabilitar la financiación <strong>${item.descripcion}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitada ? habilitar(item) : inhabilitar(item)"
          />
          <ModificarAlicuota
            v-if="!item.inhabilitada"
            :p_financiacion="item"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
      <!-- BOTONES -->
      <v-row class="d-flex justify-end mx-0 mt-1 mb-3">
        <SelectedCounter
          :selected.sync="selected"
          :items.sync="financiaciones"
          :custom="true"
          @criterio="select_all()"
        />
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          class="ma-2"
          :disabled="selected.length == 0"
          @click="setGrupoComis()"
        >
          <v-icon left>far fa-object-group</v-icon>
          Actualizar Grupo de Comisiones
        </v-btn>
        <v-btn
          color="light-green"
          class="mt-2"
          :dark="selected.length > 1"
          :disabled="selected.length <= 1"
          @click="dialog = true"
        >
          <v-icon left>fas fa-percentage</v-icon>
          CAmbio alicuota masivo
        </v-btn>
      </v-row>
    </v-col>
    <!-- COMPONENTES -->
    <ModificarAlicuotaMasivo
      v-model="dialog"
      :financiaciones="selected"
      @actualizar="buscar()"
    />
    <!-- Modal Actualizar Grupo Comisiones Masivo -->
    <ModalGrupoComis 
      :datos="dialogGrupoComis"
      @setearActGC="setActGC"
    />
    <!-- Modal Ver Periodos de Grupo de las Financiaciones -->
    <ModalPeriodosGrupoFP 
      :datos="dialogPeriodosGrupo"
      @setearPeriodosFP="setPeriodosFP"
    />
    <!-- Menu del Click Derecho -->
    <v-menu
      v-model="menu.activo"
      :position-x="menu.x"
      :position-y="menu.y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          @click="dialogPeriodosGrupo.activo = true; dialogPeriodosGrupo.fp_cond_id = selectedFP.id; dialogPeriodosGrupo.nombre = selectedFP.descripcion"
        >
          <v-list-item-title>
            <v-icon color="success" small left>far fa-eye</v-icon>
            Ver Periodo de Grupos
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { order_list_by } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import SelectedCounter from '../../components/util/SelectedCounter'
import ModificarAlicuota from '../../components/auditoria/ModificarAlicuota'
import ModificarAlicuotaMasivo from '../../components/auditoria/ModificarAlicuotaMasivo'
import ModalGrupoComis from '../../components/auditoria/ModalGrupoComis.vue'
import ModalPeriodosGrupoFP from '../../components/auditoria/ModalPeriodosGrupoFP.vue'

export default {
  data () {
    return {
      panel: 0,
      search: '',
      load: false,
      dialog: false,
      cuotas: [],
      selected: [],
      financiaciones: [],
      headers: [
        { text: 'Código', value: 'id', align: 'end' },
        { text: 'Tarjeta', value: 'tarjeta_nombre' },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Cuota', value: 'cuota', align: 'end' },
        { text: 'Vig. desde', value: 'desde' },
        { text: 'Vig. hasta', value: 'hasta' },
        { text: 'Alicuota', value: 'alicuota', align: 'end' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
      ],
      filtro: {
        empresa: null,
        sucursal_codigo: null,
        tarjetas: [],
        cuotas: null,
        formas_pago: null,
        formas_cobro: null,
        descripcion: '',
        inhabilitada: 0,
        canal: null
      },
      canales: [],
      dialogGrupoComis: {
        activo: false,
        financiaciones: [],
        grupos: []
      },
      menu: {
        activo: false,
        x: 0,
        y: 0
      },
      dialogPeriodosGrupo: {
        activo: false,
        fp_cond_id: 0,
        nombre: '',
        grupos: []
      },
      selectedFP: {}
    }
  },
  async created () {
    // si tiene solo 1 empresa la selecciona
    if (this.una_empresa) {
      this.filtro.empresa = this.empresas[0].id
    }
    // obtiene los datos necesarios
    this.$store.state.loading = true
    await this.$store.dispatch('tarjetas/get_tarjetas_habilitadas')
    await this.$store.dispatch('pagos/get_formas_pago_habilitadas')
    await this.$store.dispatch('cobros/get_formas_cobro_habilitadas')
    this.canales = JSON.parse(localStorage.getItem('canales'))
    let gruposPeticion = await this.$store.dispatch('financiaciones/get_grupos_comisiones')
    if (gruposPeticion.resultado == 0){
      this.$store.state.loading = false
      this.$store.dispatch('show_snackbar', {
        text: gruposPeticion.msj,
        color: 'error'
      })
      return
    }
    this.dialogGrupoComis.grupos = gruposPeticion.grupos
    this.dialogPeriodosGrupo.grupos = gruposPeticion.grupos
    this.$store.state.loading = false
  },
  computed: {
    ...mapGetters(['una_empresa']),
    ...mapState(['empresas','sucursales']),
    ...mapState('tarjetas', ['tarjetas']),
    ...mapState('pagos', ['formas_pago']),
    ...mapState('cobros', ['formas_cobro'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    SelectedCounter,
    ModificarAlicuota,
    ModificarAlicuotaMasivo,
    ModalGrupoComis,
    ModalPeriodosGrupoFP
  },
  methods: {
    async buscar () {
      if (this.filtro.empresa) {
        this.load = true
        this.search = ''
        this.selected = []
        this.financiaciones = []
        await this.$store.dispatch('financiaciones/get_financiaciones', this.filtro)
          .then((res) => {
            this.financiaciones = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.load = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una empresa',
          color: 'warning'
        })
      }
    },
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('financiaciones/habilitar', item.id)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          this.quitar(item)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('financiaciones/inhabilitar', item.id)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          this.quitar(item)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async get_cuotas () {
      this.cuotas = []
      this.filtro.cuotas = null
      if (this.filtro.tarjetas.length > 1) return
      const empresa = this.filtro.empresa
      const tarjeta = this.filtro.tarjetas[0]
      if (empresa && tarjeta) {
        let tarjetas = []
        tarjetas.push(tarjeta)
        this.$store.state.loading = true
        await this.$store.dispatch('cuotas/get_cuotas', {
          empresa: empresa,
          tarjetas: tarjetas,
          inhabilitada: 0
        })
          .then((res) => {
            this.cuotas = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        order_list_by(this.cuotas, 'nombre')
        this.$store.state.loading = false 
      }
    },
    quitar (item) {
      const index = this.financiaciones.indexOf(item)
      this.financiaciones.splice(index, 1)
    },
    open_window (id) {
      let path = '/financiacion'
      if (id) {
        path = `${path}/${id}`
      }
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    select_all () {
      this.selected = []
      this.financiaciones.forEach(fin => {
        this.selected.push(fin)
      })
    },
    selectAllToggle () {
      if (this.selected.length < this.financiaciones.length) this.select_all()
      else this.selected = []
    },
    limpiar () {
      this.selected = []
      this.cuotas = []
      this.filtro = {
        empresa: null,
        tarjetas: [],
        cuotas: null,
        formas_pago: null,
        formas_cobro: null,
        descripcion: '',
        inhabilitada: 0
      }
    },
    setGrupoComis(){
      this.dialogGrupoComis.financiaciones = this.selected.map(element => ({
        id: element.id
      }))
      this.dialogGrupoComis.activo = true
    },
    setActGC(value){
      this.dialogGrupoComis.activo = value
      if (this.dialogGrupoComis.activo == false) this.dialogGrupoComis.financiaciones = []
    },
    setPeriodosFP(value){
      this.dialogPeriodosGrupo.activo = value
      if (this.dialogPeriodosGrupo.activo == false){
        this.dialogPeriodosGrupo.fp_cond_id = 0
        this.dialogPeriodosGrupo.nombre = ''
      }
    },
    clickDerecho(event, item){
      event.preventDefault()
      this.selectedFP = {}
      this.menu.activo = false
      this.menu.x = event.clientX
      this.menu.y = event.clientY
      this.$nextTick(() => {
        this.menu.activo = true
        this.selectedFP = item.item
      })
    },
    setStyle(item){
      return (item.id == this.selectedFP.id) ? ['font-weight-bold'] : ''
    }
  }
}
</script>