<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="1000"
    >
      <v-card
        flat
      >
        <v-card-title>
          <v-icon left class="ma-2">far fa-object-group</v-icon> Actualización Masiva de Grupo de Comisiones
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="activo = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12">
              <v-card
                flat
                outlined
              >
                <v-card-text>
                  <!-- Grupo que se va a aplicar a todo el listado -->
                  <v-form @submit.prevent="controlGrupo()">
                    <v-row :no-gutters="$vuetify.breakpoint.xs">
                      <v-col cols="12" sm="4" md="4" class="py-1">
                        <v-autocomplete
                          v-model="grupoLocal.grupo"
                          label="Grupo Comisiones"
                          item-text="grupo_nombre"
                          item-value="grupo_id"
                          :items="grupos"
                          clearable
                          outlined
                          dense
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" class="py-1">
                        <FechaPicker
                          v-model="grupoLocal.fecha_desde"
                          label="Vigencia desde"
                        />
                      </v-col>
                      <v-col cols="12" sm="3" md="3" class="py-1">
                        <FechaPicker
                          v-model="grupoLocal.fecha_hasta"
                          label="Vigencia hasta"
                        />
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-1">
                        <v-btn
                          small
                          color="success"
                          @click="controlGrupo()"
                        >
                          <v-icon left>far fa-check-circle</v-icon>
                          Aplicar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row :no-gutters="$vuetify.breakpoint.xs">
                    <v-col cols="12">
                      <v-data-table
                        class="cebra elevation-2 mt-2"
                        :headers="headers"
                        :items="detalles"
                        :loading="load"
                        dense
                        :search="search"
                      >
                        <!-- Buscador en el datatable -->
                        <template v-slot:top>
                          <v-row class="d-flex justify-end pa-2" no-gutters>
                            <v-col cols="6" sm="3">
                              <SearchDataTable
                                v-model="search"
                              />
                            </v-col>
                          </v-row>
                        </template>
                        <!-- Sin datos -->
                        <template v-slot:no-data>
                          <v-alert
                            class="mx-auto mt-4"
                            max-width="400"
                            type="warning"
                            border="left"
                            dense
                            text
                          >
                            Sin Financiaciones para mostrar.
                          </v-alert>
                        </template>
                        <!-- Acciones -->
                        <template v-slot:[`item.acciones`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="error" @click="quitarFP(item)" v-on="on">
                                <v-icon small>far fa-times-circle</v-icon>
                              </v-btn>
                            </template>
                            <span>Quitar</span>
                          </v-tooltip>
                          <v-tooltip bottom v-if="item.no_aplica == 1">
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="warning" v-on="on">
                                <v-icon small>fas fa-exclamation-triangle</v-icon>
                              </v-btn>
                            </template>
                            <span>La Fecha a aplicar es Menor a la Fecha de Inicio de esta Financiación.</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Modal Confirmar APLICAR GRUPO -->
    <ModalConfirmar 
      :activoDialog="dialogConf"
      :texto="`¿Está seguro de asociar el grupo ${(grupos.length > 0 && !cadenaVacia(grupoLocal.grupo)) ? grupos.filter(element => element.grupo_id == grupoLocal.grupo)[0].grupo_nombre : ''} a las financiaciones listadas?`"
      @action="setGrupoMasivo()"
      @setearModalConfAccion="setAsociar"
    />
  </div>
</template>

<script>
import moment from 'moment'
import FechaPicker from '../util/FechaPicker.vue'
import SearchDataTable from '../util/SearchDataTable.vue'
import ModalConfirmar from '../util/ModalConfirmar.vue'
import { cadenaVacia } from '../../util/utils'
export default {
  props: {
    datos: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearActGC', value)
      }
    },
    financiaciones: {
      get(){
        return this.datos.financiaciones
      }
    },
    grupos: {
      get(){
        return this.datos.grupos
      }
    }
  },
  components: {
    FechaPicker,
    SearchDataTable,
    ModalConfirmar
  },
  data() {
    return {
      cadenaVacia: cadenaVacia,
      grupoLocal: {
        grupo: null,
        fecha_desde: moment(new Date()).format('DD/MM/YYYY'),
        fecha_hasta: null
      },
      load: false,
      headers: [
        { text: 'Id', align: 'center', value: 'fp_cond_id' },
        { text: 'Financiación', align: 'left', value: 'nombre' },
        { text: 'Grupo', align: 'center', value: 'grupo_nombre' },
        { text: 'Fecha Desde', align: 'center', value: 'fecha_desde' },
        { text: 'Fecha Hasta', align: 'center', value: 'fecha_hasta' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      detalles: [],
      search: '',
      dialogConf: false
    }
  },
  methods: {
    async initModal(){
      this.load = true
      let initPeticion = await this.$store.dispatch('financiaciones/get_grupo_actual', {financiaciones: this.financiaciones})
      this.load = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error'
        })
        return
      }
      this.detalles = initPeticion.grupos
    },
    clearModal(){
      this.limpiar()
      this.detalles = []
      this.search = ''
      this.dialogConf = false
    },
    limpiar(){
      this.grupoLocal = {
        grupo: null,
        fecha_desde: moment(new Date()).format('DD/MM/YYYY'),
        fecha_hasta: null
      }
    },
    quitarFP(item){
      let pos = this.detalles.indexOf(item)
      this.detalles.splice(pos, 1)
    },
    async setGrupoMasivo(){
      this.$store.state.loading = true
      let aplicarPeticion = await this.$store.dispatch('financiaciones/set_grupo_actual', {
        nuevo_grupo: this.grupoLocal,
        financiaciones: this.detalles
      })
      this.$store.state.loading = false
      if (aplicarPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: aplicarPeticion.msj,
          color: 'error'
        })
        return
      }
      this.$store.dispatch('show_snackbar', {
        text: 'Se actualizaron los Grupos exitosamente.',
        color: 'success'
      })
      this.activo = false
    },
    setAsociar(value){
      this.dialogConf = value
    },
    controlGrupo(){
      if (cadenaVacia(this.grupoLocal.grupo) || cadenaVacia(this.grupoLocal.fecha_desde)){
        this.$store.dispatch('show_snackbar', {
          text: 'Se debe ingresar al menos el Grupo y Vigencia Desde para continuar.',
          color: 'warning'
        })
        return
      }
      // controlo que ninguna financiación tenga fecha desde mayor a la aplicada
      let mayor = this.detalles.filter(element => moment(element.fecha_desde, 'DD/MM/YYYY').toDate() > moment(this.grupoLocal.fecha_desde, 'DD/MM/YYYY').toDate())
      if (mayor.length > 0){
        // indico que no pueden aplicar el grupo sobre los detalles en mayor
        this.$store.dispatch('show_snackbar', {
          text: 'La Fecha a aplicar no puede ser menor que ninguna de las Fechas de Inicio de las Financiaciones listadas.',
          color: 'warning'
        })
        for (let id in mayor){
          let pos = this.detalles.indexOf(mayor[id])
          this.detalles[pos].no_aplica = 1
        }
        return
      }
      // controlo que la vigencia desde no supere a la vigencia hasta
      if (!cadenaVacia(this.grupoLocal.fecha_hasta) && moment(this.grupoLocal.fecha_hasta, 'DD/MM/YYYY').toDate() < moment(this.grupoLocal.fecha_desde, 'DD/MM/YYYY').toDate()){
        this.$store.dispatch('show_snackbar', {
          text: 'La Vigencia Desde no puede superar a la Vigencia Hasta.',
          color: 'warning'
        })
        return
      }
      for (let id in this.detalles) this.detalles[id].no_aplica = 0
      // pido confirmación
      this.dialogConf = true
    }
  },
  watch: {
    activo: function(){
      if (this.activo == true) this.initModal()
      else this.clearModal()
    }
  },
}
</script>

<style>

</style>